/* assets/scss/registration-form.scss */

.iste-registration {
    &-wrapper {
        max-width: 800px;
        margin: 0 auto;
        position: relative;
    }

    &-form {
        background: var(--color-white);
        padding: 50px 40px;
        border-radius: 20px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

        &__header {
            margin-bottom: 40px;
            text-align: center;
        }

        &__title {
            font-size: 32px;
            font-weight: 700;
            color: var(--color-heading);
            margin-bottom: 15px;
        }

        &__field {
            margin-bottom: 25px;
            position: relative;
        }
        &__textarea {
            height: 120px;
            padding: 15px 20px;
            resize: vertical;
        }

        &__label {
            display: block;
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 500;
            color: #1B1B1B; // Dark text for labels
        }

        &__error {
            display: flex;
            align-items: center;
            margin-top: 6px;
            color: #FF4D4F;
            font-size: 14px;

            svg {
                margin-right: 6px;
                width: 16px;
                height: 16px;
            }
        }


        &__input,
        &__select {
            width: 100%;
            height: 55px;
            background-color: #F8F8F8; // Light gray background
            border: 1px solid #E3E6E9;
            border-radius: 8px;
            padding: 0 20px;
            font-size: 16px;
            color: #1B1B1B; // Dark text color
            transition: all 0.3s;

            &::placeholder {
                color: #6B7074;
            }

            &:focus {
                background-color: #FFFFFF;
                border-color: var(--color-primary);
                box-shadow: 0 0 0 3px rgba(var(--color-primary-rgb), 0.1);
                outline: none;
            }

            &--error {
                border-color: #FF4D4F;
                background-color: #FFF2F2;
            }
        }
        &__textarea {
            width: 100%;
            min-height: 120px;
            padding: 15px 20px;
            resize: vertical;
            background-color: #F8F8F8;
            border: 1px solid #E3E6E9;
            border-radius: 8px;
            font-size: 16px;
            color: #1B1B1B;
            font-family: inherit; // Ensures consistent font
            line-height: 1.5;
            vertical-align: top; // Helps with text alignment
            
            &::placeholder {
                color: #6B7074;
                opacity: 1; // Ensures placeholder is visible in all browsers
            }
        }
        &__select {
            width: 100%;
            height: 55px;
            padding: 0 20px;
            background-color: #F8F8F8;
            border: 1px solid #E3E6E9;
            border-radius: 8px;
            font-size: 16px;
            color: #1B1B1B;
            cursor: pointer;
            appearance: none;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 20px center;
            
            &:hover {
                border-color: var(--color-primary);
                background-color: #FFFFFF;
            }
        }

        &__branch-section {
            display: flex;
            gap: 15px;

            .branch-select,
            .section-select {
                flex: 1;
            }
        }

        &__submit {
            width: 100%;
            height: 60px;
            background: var(--color-primary);
            border: none;
            border-radius: 8px;
            color: var(--color-white);
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background: var(--color-secondary);
            }
        }
    }

    &-success {
        text-align: center;
        padding: 60px 40px;
        background: var(--color-white);
        border-radius: 20px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

        &__icon {
            width: 70px;
            height: 70px;
            background: var(--color-success-light);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 20px;

            svg {
                width: 35px;
                height: 35px;
                color: var(--color-success);
            }
        }

        &__title {
            font-size: 28px;
            font-weight: 700;
            color: var(--color-heading);
            margin-bottom: 15px;
        }

        &__message {
            font-size: 16px;
            color: var(--color-body);
        }
        &__input:hover,
        &__select:hover,
        &__textarea:hover {
            border-color: var(--color-primary);
            background-color: #FFFFFF;
        }
    }
}

// Responsive styles
@media (max-width: 991px) {
    .iste-registration-form {
        padding: 40px 30px;

        &__title {
            font-size: 28px;
        }
    }
}

@media (max-width: 767px) {
    .iste-registration-form {
        padding: 30px 20px;

        &__title {
            font-size: 24px;
        }

        &__submit {
            height: 50px;
            font-size: 16px;
        }
    }
}